import React, { useState } from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"
import { Fade } from "react-awesome-reveal"
import Lottie from "lottie-react"
import successAnimation from "../assets/lottie/lf30_editor_kblgsvip.json"
import { BText } from "../components/chakra/BText"
import { Box } from "@chakra-ui/react"

function ContactPage({ data }) {
  const [contact, setContact] = useState({
    name: "",
    email: "",
    subject: "Bitlight Website Form",
    honeypot: "", // if any value received in this field, form submission will be ignored.
    message: "",
    $budget: "",
    replyTo: "@", // this will set replyTo of email to email address entered in the form
    accessKey: "5d098019-d7c6-485c-bf2b-45bc63b3f135", // get your access key from https://www.staticforms.xyz
  })

  const [response, setResponse] = useState({
    type: "",
    message: "",
  })

  const handleChange = e =>
    setContact({ ...contact, [e.target.name]: e.target.value })

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      const res = await fetch("https://api.staticforms.xyz/submit", {
        method: "POST",
        body: JSON.stringify(contact),
        headers: { "Content-Type": "application/json" },
      })

      const json = await res.json()

      if (json.success) {
        setResponse({
          type: "success",
          message: "Thank you for reaching out to us.",
        })
      } else {
        setResponse({
          type: "error",
          message: json.message,
        })
      }
    } catch (e) {
      console.log("An error occurred", e)
      setResponse({
        type: "error",
        message: "An error has occurred while submitting the form :(",
      })
    }
  }
  const { siteMetadata } = data.site || {}
  const { title, strapiUrl, siteUrl } = siteMetadata || {}
  return (
    <Layout
      duration={"0"}
      delay={"0"}
      title={title}
      strapiUrl={strapiUrl}
      logo={`${strapiUrl}/uploads/bitlight_logo_B2_2b1b649637.png`}
      backgroundColor={"#FFD600"}
      siteUrl={siteUrl}
    >
      <Seo
        title={"Contact"}
        keywords={["bitlight", "contact", "hireus", "project"]}
      />
      <section id={"contact-hero"}>
        <div id={"contact-hero-spacer-top"} />
        <Box
          className={"max-width"}
          style={{ width: "100vw" }}
          // px={"50px"}
        >
          <Fade duration={1000} delay={500} triggerOnce={true}>
            <BText as={"h1"} id={"contact-hero-title"}>
              Get in touch.
            </BText>
            <br />
            <BText as={"h1"} id={"contact-hero-subtitle"}>
              Since you&apos;re interested in working with us, or have an idea
              you wish to realize, we invite you to reach out to us!
            </BText>
          </Fade>
        </Box>
        <div id={"contact-hero-spacer-bottom"} />
      </section>
      <div id={"form-container"}>
        <Lottie
          animationData={successAnimation}
          id={"success-animation"}
          className={response.type === "success" ? "visible" : "hidden"}
        />
        <BText
          fontSize={"3.2rem"}
          textAlign={"center"}
          className={response.type === "success" ? "visible" : "hidden"}
        >
          {response.message}
        </BText>
        <div
          id={"form-visibility"}
          className={response.type === "success" ? "hidden" : "visible"}
          style={{
            visibility: "hidden",
          }}
        >
          <BText
            fontSize={"3.2rem"}
            lineHeight={1.4}
            as={"h3"}
            id={"form-title"}
          >
            Tell us more about yourself and your idea
          </BText>
          <div className={"column  is-two-thirds"}>
            <div
              className={
                response.type === "error"
                  ? "tile box notification is-danger"
                  : "is-hidden"
              }
            >
              <BText>{response.message}</BText>
            </div>
            <div
              className={response.message !== "" ? "is-hidden" : "columns"}
            />
          </div>
          <form
            action={"https://api.staticforms.xyz/submit"}
            method={"post"}
            onSubmit={handleSubmit}
          >
            <div className={"field"} style={{ display: "none" }}>
              <label className={"label"}>Title</label>
              <div className={"control"}>
                <input
                  type={"text"}
                  name={"honeypot"}
                  style={{ display: "none" }}
                  onChange={handleChange}
                />
                <input
                  type={"hidden"}
                  name={"subject"}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={"row gtr-uniform"}>
              <div className={"col-6 col-12-xsmall"}>
                <input
                  style={{ fontFamily: "GT Walsheim" }}
                  type={"text"}
                  name={"name"}
                  onChange={handleChange}
                  placeholder={"Name"}
                  required={true}
                />
              </div>
              <div className={"col-6 col-12-xsmall"}>
                <input
                  style={{ fontFamily: "GT Walsheim" }}
                  type={"email"}
                  name={"email"}
                  placeholder={"Email"}
                  required={true}
                  onChange={handleChange}
                />
              </div>
              {/* Break */}
              <div className={"col-12"}>
                <select
                  style={{ fontFamily: "GT Walsheim", padding: "0.3em 0.6em" }}
                  name={"$budget"}
                  id={"demo-category"}
                  onChange={handleChange}
                >
                  <option value={"Budget"}>- Budget -</option>
                  <option value={"Up to 10.000 €"}>Up to 10.000 €</option>
                  <option value={"10.000€  -  50.000€"}>
                    10.000€ - 50.000€
                  </option>
                  <option value={"50.000€  -  100.000€"}>
                    50.000€ - 100.000€
                  </option>
                  <option value={"100.000€ and up"}>100.000€ and up</option>
                  <option value={"n/a"}>n/a</option>
                </select>
              </div>
              {/* Break */}
              <div className={"col-12"}>
                <textarea
                  style={{ fontFamily: "GT Walsheim", padding: "0.3em 0.6em" }}
                  name={"message"}
                  placeholder={"Your Message"}
                  rows={6}
                  defaultValue={""}
                  required={true}
                  onChange={handleChange}
                />
              </div>
              {/* Break */}
              <div className={"col-12"}>
                <input
                  style={{
                    backgroundColor: "#24272A",
                    fontFamily: "GT Walsheim",
                  }}
                  type={"submit"}
                  defaultValue={"Send Message"}
                  className={"primary"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        strapiUrl
        siteUrl
      }
    }
  }
`

function StaticContactPage(props) {
  return (
    <StaticQuery
      query={indexQuery}
      render={data => (
        <ContactPage location={props.location} data={data} {...props} />
      )}
    />
  )
}

export default StaticContactPage
